import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import { rhythm } from '../utils/typography'
import { themeStyles, colors, presets, metrics } from '../utils/theme'
import _ from 'lodash'
import ContactForm from '../components/ContactForm'


const styles = {
}


const Contact = ({ data, location }) => {
  return (
    <Layout hideForm>
      <div css={[themeStyles.contentWidth, {textAlign: 'center', backgroundColor: colors.lightGrey}]}>
        <div css={[themeStyles.textPadding, {display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}]}>
          <h1>We'll take your stress away.</h1>
          <p>Get in touch below for a free quote.
          <br/>
          <strong>You'll be happy you did.</strong></p>
        </div>
      </div>
      <div css={[themeStyles.contentWidth, { paddingBottom: metrics.defaultPadding, backgroundColor: colors.lightGrey}]}>
        <div css={[themeStyles.textPadding, {display: 'flex', alignItems: 'center', justifyContent: 'center'}]}>
          <ContactForm formName='Contact'/>
        </div>
      </div>
    </Layout>
  )
}

export default Contact
